<template>
	<div class="view pa24" >
		<div style="display: flex;flex-wrap: wrap;"> 
			<div class="mr10 mb10">
				<span class="mr10">单号</span>
				<el-input clearable class="w220 mr10" v-model="search.name" placeholder="请输入单号" />
			</div>
			<div class="mr10"> 
				<span class="mr10">银行卡号</span>
				<el-input clearable class="w220 mr10" v-model="search.bank" placeholder="请输入银行卡号" />
			</div>
			<div class="mr10">
				<span class="mr10">开户名</span>
				<el-input clearable class="w220 mr10" v-model="search.bank" placeholder="请输入开户名" />
			</div>
			<div class="mr10">
				<span class="mr10">金额</span>
				<el-input clearable class="w220 mr10" v-model="search.money" placeholder="请输入金额" />
			</div>
			<div class="mr10">
				<span class="mr10">申请时间</span>
				<el-date-picker v-model="search.date" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
			<!-- <div class="mr10">
				<span class="mr10">状态</span>
				<el-select clearable v-model="search.status" class="w220 mb10 mr10" placeholder="请选择状态">
					<el-option v-for="(item,index) in fashiList" :key="'info4'+ index" :label="item.name"
						:value="item.index">
					</el-option>
				</el-select>
			</div> -->
			<!-- <el-button class="ml10 h34" type="primary" @click="search">查询</el-button> -->
			<!-- <el-button class="mr10 h34" type="danger" @click="">导出</el-button> -->
		</div>
		<div class="mt20">
			 <el-tag effect="plain" class="mr10">成功次数：0</el-tag>
			 <el-tag effect="plain" class="mr10" type="success">成功金额：0</el-tag>
		</div>
		<commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
			@handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
			<template v-slot:table>
				<el-table-column prop="createName" align="center" label="申请时间"  />
				<el-table-column prop="windowsName" align="center" label="单号"  />
				<el-table-column prop="createName" align="center" label="银行卡信息"  />
				<el-table-column prop="createTime" align="center" label="提交金额" />
				<el-table-column prop="createName" align="center" label="费用"  />
				<el-table-column prop="createName" align="center" label="到账金额"  />
				<el-table-column prop="createName" align="center" label="IP"  />
				<el-table-column prop="createName" align="center" label="审核时间"  />
				<el-table-column prop="createName" align="center" label="状态"  />
				<el-table-column prop="createName" align="center" label="备注"  />
			</template>
		</commonTable>
	</div>
</template>

<script>
	import commonTable from "@/components/common/commonTable";
	import {
		mapState
	} from 'vuex';
	import {} from "@/api/settlement";
	let ac;
	export default {
		name: 'windowSet',
		data() {
			return {
				search:{
					name:'',
					bank:'',
					money:'',
					status:'',
					date:''
				},
				fashiList: [],
				tableData: [],
				currentPage: 1, //当前页
				pageSize: 10, //显示条数
				loading: false, //表格加载
				total: 0, //总条数
			};
		},
		components: {
			commonTable,
		},
		mounted() {
			// this.queryPage();
		},

		methods: {
			async queryPage() {
				let data = {
					pageSize: this.pageSize,
					pageNum: this.currentPage,
				};
				try {
					this.loading = true;
					const result = await selectWindowsConfigPageList(data);
					this.loading = false;
					const {
						total,
						list
					} = result.data.pageInfo;
					for (let i in list) {
						list[i].createTime = this.renderTime(list[i].createTime)
						if (list[i].status == 0) {
							list[i].status = true
						} else {
							list[i].status = false
						}
					}
					this.tableData = list;
					this.total = total;
				} catch (error) {
					this.tableData = []
					this.loading = false;
				}
			},
			renderTime(date) {
				var dateee = new Date(date).toJSON();
				return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/,
					'')
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.queryPage();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.queryPage();
			},
			/**@method 编辑
			 * @param {Object} val - 当前点击行的值
			 */
			edit(val, b) {
				let data = JSON.parse(JSON.stringify(val));
				this.centerDialogVisible = true;
				this.ruleForm.account = data.windowsName;
				this.ruleForm.bank = data.windowsPerson;
				this.ruleForm.zhihang = data.status;
				this.ruleForm.name = data.officeId
				this.ruleForm.ka = data.officeId
				this.ruleForm.desc = data.officeId
				this.bdetermine = b;
				this.bEdit = b;
				this.windowsConfigId = data.windowsConfigId
			},
			/**@method 添加 */
			determine() {
				this.$refs.ruleForm.validate(async (valid) => {
					if (valid) {
						try {
							let data = {
								account: this.ruleForm.account,
								bank: this.ruleForm.bank,
								zhihang: this.ruleForm.zhihang,
								name: this.ruleForm.name,
								ka: this.ruleForm.ka,
								desc: this.ruleForm.desc,
							};
							this.$message({
								message: "正在保存",
							});
							if (this.editUpdate) {
								data.windowsConfigId = this.windowsConfigId
								await upWindowsConfig(data);
							} else {
								await addWindowsConfig(data);
							}
							this.centerDialogVisible = false;
							this.$message.closeAll();
							this.$message({
								message: "保存成功",
								type: "success",
							});
							await this.queryPage();
						} catch (error) {
							this.$message.closeAll();
							this.$message({
								message: error.message,
								type: "error",
							});
							this.centerDialogVisible = false;
						}
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			/**@method 删除
			 * @param {String}
			 */
			async del(val) {
				try {
					await delWindowsConfig({
						windowsConfigId: val
					});
					await this.queryPage();
				} catch (error) {
					console.log(error);
				}
			},
			/**@method 模态框关闭的回调 */
			close() {
				(this.ruleForm = {
					account: "",
					bank: "",
					zhihang: "",
					name: "",
					desc: "",
					ka: "",
				}),
				this.centerDialogVisible = false;
			},
		},
	};
</script>

<style lang="scss" scoped>
</style>